import box from "/imgs/Rectangle2.png";
import box2 from "/imgs/Rectangle150.png";
function Upload() {
  return (
    <>
      {/* <button
      className="bg-black p-4 rounded-md block text-white"
      onClick={() => {
        playRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }}
    >
      play
    </button> */}
      <h3 className="text-2xl uppercase">file upload</h3>

      <div className=" py-4_ mx-auto_" id="play">
        <h4 className="text-xl pb-2" id="">
          Sub chapter 1.1
        </h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className=" py-4" id="1-1-1">
        <h4 className="text-xl pb-2">Sub chapter 1.1.1</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className=" py-4" id="1-1-2">
        <h4 className="text-xl pb-2">Sub chapter 1.1.2</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className=" py-4" id="1-1-3">
        <h4 className="text-xl pb-2">Sub chapter 1.1.3</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className="box flex flex-col lg:flex-row justify-center items-center gap-4 py-4">
        <div>
          <img src={box} alt="" />
          <div className="max-w-[200px] text-sm text-center mx-auto">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
          </div>
        </div>
        <div>
          <img src={box} alt="" />
          <div className="max-w-[200px] text-sm text-center mx-auto">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
          </div>
        </div>
        <div>
          <img src={box} alt="" />
          <div className="max-w-[200px] text-sm text-center mx-auto">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.
          </div>
        </div>
      </div>
      <div className=" py-4" id="1-1-4">
        <h4 className="text-xl pb-2">Sub chapter 1.1.4</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className=" py-4" id="1-2">
        <h4 className="text-xl pb-2">Sub chapter 1.2</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
      </div>
      <div className=" py-4 space-y-4" id="1-3">
        <h4 className="text-xl pb-2">Sub chapter 1.3</h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam.
        <div>
          <img src={box2} alt="" />
          <p className="text-center">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut.
          </p>
        </div>
      </div>
    </>
  );
}

export default Upload;
