/* eslint-disable no-undef */
export enum MIC_STATE {
  REQ_MIC = "REQ_MIC",
  CAN_SPK = "CAN_SPK",
  MIC_OFF = "MIC_OFF",
  MIC_MUTED = "MIC_MUTED"
};

// export const SERVER_URL = "http://localhost:4000";

export const SERVER_URL = "https://pptlinks-backend.onrender.com";

export const AGORA_APP_ID = "33672aae3d8d48f4b83292008db3986e";

export const HOME = "/";

export const INSTITUTIONS = "/institutions";

export const INSTITUTIONS_ID = "/institutions/:id";

export const ABOUT = "/about";

export const DASHBOARD = "/dashboard";

export const LEGAL = "/legal";

export const UPLOAD = "/upload";

export const SIGNUP = "/signup";

export const LOGIN = "/login";

export const DOCUMENT = "/documentation";
